import SuperTokens from "supertokens-auth-react";
import Session from "supertokens-auth-react/recipe/session";
import ThirdPartyEmailPassword, { Google } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import {notifications} from "@mantine/notifications";

function initializeSuperTokens() {
    SuperTokens.init({
        appInfo: {
            // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
            appName: "my app",
            apiDomain: process.env.REACT_APP_BACKEND_URL,
            websiteDomain: process.env.REACT_APP_FRONTEND_URL,
            apiBasePath: "/auth",
            websiteBasePath: "/auth"
        },
        recipeList: [
            Session.init(),
            ThirdPartyEmailPassword.init({
                onHandleEvent: async (context) => {
                    if (context.action === "SESSION_ALREADY_EXISTS") {
                        console.log("TODO:");
                    } else {
                        if (context.action === "SUCCESS") {
                            if (context.isNewRecipeUser && context.user.loginMethods.length === 1) {
                                notifications.show({
                                    title: 'Email sent',
                                    message: 'Check your inbox for instructions to access predictions!',
                                })
                            } else {
                                console.log("TODO: Sign in");
                            }
                        }
                    }
                },
                signInAndUpFeature: {
                    providers: [
                        Google.init(),
                    ]
                }
            })
        ]
    });
}

export default initializeSuperTokens;
