import React, { useState, useEffect } from 'react';
import { Card, SimpleGrid, Text, Group, LoadingOverlay, useMantineTheme, Badge, Divider } from '@mantine/core';
import { IconTrendingUp, IconTrendingDown } from '@tabler/icons-react';
import axios from 'axios';
import classes from '../StatsGrid.module.css'; // Ensure your CSS module is set up correctly

function AccuracyStatsGrid({ originLid, originCategory }) {
    const [loading, setLoading] = useState(false);
    const [accuracyData, setAccuracyData] = useState([]);
    const theme = useMantineTheme();

    useEffect(() => {
        setLoading(true);

        // Constructing parameters conditionally
        const params = {};
        if (originLid) params.origin_lid = originLid;
        if (originCategory) params.origin_category = originCategory;

        axios.get(`${process.env.REACT_APP_BACKEND_URL}leagues_accuracy`, { params })
            .then((response) => {
                const data = response.data.accuracy;
                const statsData = [
                    // {
                    //     title: 'High Confidence Accuracy',
                    //     badge: <Badge size="md" color="orange">High Confidence</Badge>,
                    //     icon: IconTrendingUp,
                    //     value: `${data.accuracy_overall.super_recommended.percentage}%`,
                    //     diff: data.accuracy_overall.super_recommended.trend,
                    //     count: `(${data.accuracy_overall.super_recommended.correct}/${data.accuracy_overall.super_recommended.total})`
                    // },
                    {
                        title: 'Recommended Accuracy',
                        badge: <Badge size="md" color="green">Recommended</Badge>,
                        icon: IconTrendingUp,
                        value: `${data.accuracy_overall.recommended.percentage}%`,
                        diff: data.accuracy_overall.recommended.trend,
                        count: `(${data.accuracy_overall.recommended.correct}/${data.accuracy_overall.recommended.total})`
                    },
                    {
                        title: 'Overall Accuracy',
                        badge: <Badge size="md" color="blue">Overall</Badge>, // Assuming you want a badge here too for consistency
                        icon: IconTrendingUp,
                        value: `${data.accuracy_overall.overall.percentage}%`,
                        diff: data.accuracy_overall.overall.trend,
                        count: `(${data.accuracy_overall.overall.correct}/${data.accuracy_overall.overall.total})`
                    },
                ];
                setAccuracyData(statsData);
            })
            .catch((error) => {
                console.error('Failed to fetch accuracy data:', error);
            })
            .finally(() => setLoading(false));
    }, [originLid, originCategory]);

    const stats = accuracyData.map((stat) => {
        const Icon = stat.diff > 0 ? IconTrendingUp : IconTrendingDown;

        return (
            <Card
                withBorder
                p="md"
                radius="md"
                key={stat.title}
                className={classes.paper}
                style={{ backgroundColor: theme.colors.blue[4] }}
            >
                <Group justify="space-between" align="center">
                    <Text size="xs" className={classes.title}>
                        {stat.badge}
                    </Text>
                    <Icon className={classes.icon} size={24} />
                </Group>
                <Group align="flex-end" gap="xs" mt="md">
                    <Text size="lg" className={classes.value}>{stat.value}</Text>
                    <Text size="sm" style={{ color: 'white' }}>{stat.count}</Text>
                </Group>
            </Card>
        );
    });

    return (
        <div className={classes.root}>
            <LoadingOverlay visible={loading} />
            <SimpleGrid cols={3} breakpoints={[
                { maxWidth: '62rem', cols: 3, spacing: 'md' },
                { maxWidth: '48rem', cols: 2, spacing: 'sm' },
                { maxWidth: '36rem', cols: 1, spacing: 'sm' },
            ]}>
                {stats}
            </SimpleGrid>
            <Divider my="lg" /> {/* Add a horizontal separator after the grid */}
        </div>
    );
}

export default AccuracyStatsGrid;
