import './App.css';
import {useSessionContext} from "supertokens-auth-react/recipe/session";
import {canHandleRoute, getRoutingComponent} from "supertokens-auth-react/ui";
import {ThirdPartyEmailPasswordPreBuiltUI} from "supertokens-auth-react/recipe/thirdpartyemailpassword/prebuiltui";
import {useMutation, useQuery} from "@tanstack/react-query";
import PredictionGame from "./components/PredictionGame";
import {Affix, Box, Button, Container, Group, Modal, rem, Transition} from "@mantine/core";
import {useContext, useEffect, useState} from "react";
import {PredictionsCartContext} from "./PredictionsCartContext";
import axios from "axios";
import {getMockCurrentDate, groupGamesByDayLeague, groupPredictionsByGame} from "./helpers/utils";
import PredictionsByDay from "./components/PredictionsByDay";
import isDev from "./helpers/DevDetect";
import localPredictions from "./data/predictions.json";
import {forEach,map} from "lodash";
import PredictionsByLeague from "./components/PredictionsByLeague";
import {IconArrowUp, IconCheck, IconAlertCircle, IconCalendar, IconCalendarEvent} from "@tabler/icons-react";
import { Text, Anchor } from '@mantine/core';
import { Notification } from '@mantine/core';
import AccuracyStatsGrid from "./components/AccurcyStatsGrid";

function App() {
  let sessionContext = useSessionContext();
  const predictionsCart = useContext(PredictionsCartContext);
  let predictionsBought = predictionsCart.getTotalCost();
  const [checkoutClicked, setCheckoutClicked] = useState(false);

  let [success, setSuccess] = useState(false);
  let [failure, setFailure] = useState(false);
  let [notificationOpen, setNotificationOpen] = useState(false);

  useEffect(() => {
      // Check to see if this is a redirect back from Checkout
      const query = new URLSearchParams(window.location.search);
      if (query.get('success')){
          if (query.get('success') === 'true') {
              setSuccess(true);
              setNotificationOpen(true);
              window.history.pushState({}, undefined, "/");
          } else {
              setFailure(true);
              setNotificationOpen(true);
              window.history.pushState({}, undefined, "/");
          }
      }
  },[]);

  const predictionsQuery = useQuery(["predictions"],() =>
      fetch(process.env.REACT_APP_BACKEND_URL+"/predictions_v2").then((res) => res.json())
  );
    if (sessionContext.loading) {
      return null;
    }
    if (sessionContext.doesSessionExist) {
      if (predictionsQuery.data) {
          const grouped = groupGamesByDayLeague(predictionsQuery.data,new Date());
          return (
              <Container>
                  {success &&
                      <Modal opened={notificationOpen} onClose={()=>setNotificationOpen(false)}
                             styles={{body: { padding: 0 }}}
                             withCloseButton={false} centered>
                      <Notification icon={<IconCheck/>} title="Payment successful!" withBorder={false}
                             withCloseButton={false}
                      >
                          You can now access the predictions for the games you just purchased!
                          <Group position="center">
                              <Button mb="0.3rem" onClick={()=>setNotificationOpen(false)}>OK</Button>
                          </Group>
                      </Notification>
                      </Modal>
                  }
                  {failure &&
                      <Modal opened={notificationOpen} onClose={()=>setNotificationOpen(false)}
                             styles={{body: { padding: 0 }}}
                             withCloseButton={false} centered>
                          <Notification color="red" icon={<IconAlertCircle/>} title="Payment failed" withBorder={false}
                                        withCloseButton={false}
                          >
                              If you think it is a problem reach out to us
                              <Group position="center">
                                  <Button mt="0.3rem" mb="0.3rem" onClick={()=>setNotificationOpen(false)}>OK</Button>
                              </Group>
                          </Notification>
                      </Modal>
                  }
                  <h1>Overall accuracy</h1>
                  <AccuracyStatsGrid/>
                  {
                      map(grouped, (oneDay, dayIndex) => {
                          return (
                              <div key={oneDay["desc"] || dayIndex}> {/* Use oneDay["desc"] as key, fallback to dayIndex if not unique */}
                                  <Group align="center" spacing="xs" style={{ alignItems: 'center' }}>
                                      <IconCalendarEvent size={40} /> {/* Adjust size as needed */}
                                      <h1 style={{ margin: 0, display: 'inline' }}>{oneDay["desc"]}</h1>
                                  </Group>
                                  {
                                      map(oneDay["entries"], (oneLeague, leagueIndex) => {
                                          // Ensure you have a unique identifier for each league; here we use leagueIndex as a fallback
                                          return <PredictionsByLeague key={leagueIndex} league={oneLeague} predictions={oneLeague["entries"]}/>
                                      })
                                  }
                              </div>
                          )
                      })
                  }
                  {predictionsBought>0 &&
                      <Affix position={{ bottom: rem(5), right: rem(5) }}>
                      <Button w={200} h={100} loading={checkoutClicked}
                          onClick={() => {
                              setCheckoutClicked(true);
                              predictionsCart.checkout({"type":"predictions","predictions":predictionsCart.predictionsInCart});
                          }}
                      >
                          <Text size="xl">Checkout ({predictionsBought}€)</Text>
                      </Button>
                  </Affix> }
              </Container>
          )
      }
    } else {
      return <div>You are NOT logged in!</div>
    }
}

export default App;
