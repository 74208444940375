import {groupBy} from "lodash";
import PredictionsByLeague from "./PredictionsByLeague";

function PredictionsByDay(props) {
    const day = props.day;
    const predictions = props.predictions;
    const predictionsByLeague = groupBy(predictions,"lid_name");
    return (
        <>
        <div>{day}</div>
            {
                Object.keys(predictionsByLeague).map((one) => {
                    return <PredictionsByLeague league={one} predictions={predictionsByLeague[one]}></PredictionsByLeague>
                })
            }
        </>
    );
}

export default PredictionsByDay;
