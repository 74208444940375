import {forEach, groupBy, orderBy, remove} from "lodash";
import {format} from "date-fns-tz";
import {parseISO} from "date-fns";

export function isToday(date, today) {
    //const today = new Date();
    return today.toDateString() === date.toDateString();
}

export function getMockCurrentDate() {
    return new Date(2023, 4, 20);
}

export function isTomorrow(date,today) {
    const tomorrow = new Date(today.getTime());
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toDateString() === date.toDateString();
}

export function groupPredictionsByLeague(predictions){
    const toRet = groupPredictionsByGame(predictions);
    const result = [];
    const sorted = orderBy(toRet,['game.game_date','lid_name','game.home'],['asc']);
    let lastLeague = null;
    let buffer = [];
    forEach(sorted,function (one) {
       if(lastLeague===null){
           lastLeague = one["lid_name"];
           buffer.push(one);
       } else {
           const currentLeague = one["lid_name"];
           if(currentLeague===lastLeague){
               buffer.push(one);
           } else {
               result.push({
                   "lid_name": lastLeague,
                   "entries": [...buffer]
               });
               remove(buffer);
               buffer.push(one);
               lastLeague = currentLeague;
           }
       }
    });
    if(buffer.length!==0){
        result.push({
            "lid_name": lastLeague,
            "entries": buffer
        });
    }
    return result;
}

export function groupPredictionsByGame(predictions) {
    const result = groupBy(predictions,'game.gid');
    let toRet = [];
    forEach(result,function (val){
        const game = {
            "game": val[0]["game"],
            "lid_name": val[0]["lid_name"]
        };
        forEach(val,function (unit){
            if(unit["metric"]==="handicap"){
                game["handicap"] = unit;
            }else if(unit["metric"]==="overunder"){
                game["overunder"]= unit;
            }
        });
        toRet.push(game);
    });
    return toRet;
}

export function groupGamesByDayLeague(gamePredictions,today) {

    const toRet = [];
    const result = groupBy(gamePredictions, function (one){
        return format(parseISO(one["game"]["game_date"]+"Z"),'dd MMM yyyy');
    });
    forEach(result, function (value, key){
       const oneDate = parseISO(value[0]["game"]["game_date"]+"Z");
       let desc = key;
       if(isToday(oneDate,today)) {
           desc = "Today ("+key+")";
       } else if(isTomorrow(oneDate,today)) {
           desc = "Tomorrow ("+key+")";
       }
       const finalEntries = {
           "desc": desc,
           "oneDate": oneDate,
           "entries": groupPredictionsByLeague(value)
       };
       toRet.push(finalEntries);
    });
    return orderBy(toRet,['oneDate'],['asc']);
}
