import { createStyles } from '@mantine/core';

const theme = createStyles({
    colors: {
        primary: '#182A4B',
        primaryLight: '#FFFFFF',
        primaryDark: '#0E192E',
        secondary: '#5196D0',
        secondaryLight: '#D2F2FF',
        secondaryDark: '#3877C6',
        gray: {
            50: '#FAFAFA',
            100: '#F5F5F5',
            200: '#EEEEEE',
            300: '#E0E0E0',
            400: '#D5D5D5',
            500: '#C0C0C0',
            600: '#959595',
            700: '#707070',
            800: '#4D4D4D',
            900: '#262626',
        },
        dark: {
            primary: '#6A89B3',
            primaryLight: '#A4B6C8',
            primaryDark: '#475D79',
            secondary: '#B4C7DF',
            secondaryLight: '#D8E2F5',
            secondaryDark: '#9EB3C3',
            gray: {
                50: '#3C485D',
                100: '#4E5D72',
                200: '#64778B',
                300: '#788B9E',
                400: '#8C9EAD',
                500: '#A0ABBD',
                600: '#B4C7DF',
                700: '#CACFDA',
                800: '#DEDFE6',
                900: '#EFEFF7',
            },
        },
    },
    spacing: {
        xs: 2,
        sm: 4,
        md: 8,
        lg: 16,
        xl: 32,
    },
});

export default theme;
