import PredictionGame from "./PredictionGame";
import {SimpleGrid} from "@mantine/core";
import AccuracyStatsGrid from "./AccurcyStatsGrid";

function PredictionsByLeague(props){
    const league = props.league;
    const predictions = props.predictions;
    return (
        <>
        <h2>{league.lid_name}</h2>
            <AccuracyStatsGrid originLid={predictions[0].handicap.origin_lid} originCategory={predictions[0].handicap.origin_category}/>
            <SimpleGrid cols={3} breakpoints={[
                { maxWidth: '62rem', cols: 3, spacing: 'md' },
                { maxWidth: '48rem', cols: 2, spacing: 'sm' },
                { maxWidth: '36rem', cols: 1, spacing: 'sm' },
            ]}>
            {predictions.map((one,index)=>{
                return <PredictionGame key={index} data={one}/>;
            })}
            </SimpleGrid>
        </>
    )
}

export default PredictionsByLeague;
