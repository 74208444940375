import { createStyles, Text, Container, ActionIcon, Group, rem } from '@mantine/core';
import {IconBrandTwitter, IconBrandYoutube, IconBrandInstagram, IconBrandFacebook} from '@tabler/icons-react';
import { MantineLogo } from '@mantine/ds';

const useStyles = createStyles((theme) => ({
    footer: {
        marginTop: rem(120),
        paddingTop: `calc(${theme.spacing.xl} * 2)`,
        paddingBottom: `calc(${theme.spacing.xl} * 2)`,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        borderTop: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
        }`,
    },

    logo: {
        maxWidth: rem(200),

        [theme.fn.smallerThan('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },

    description: {
        marginTop: rem(5),

        [theme.fn.smallerThan('sm')]: {
            marginTop: theme.spacing.xs,
            textAlign: 'center',
        },
    },

    inner: {
        display: 'flex',
        justifyContent: 'space-between',

        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },

    groups: {
        display: 'flex',
        flexWrap: 'wrap',

        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    wrapper: {
        width: rem(160),
    },

    link: {
        display: 'block',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
        fontSize: theme.fontSizes.sm,
        paddingTop: rem(3),
        paddingBottom: rem(3),

        '&:hover': {
            textDecoration: 'underline',
        },
    },

    title: {
        fontSize: theme.fontSizes.lg,
        fontWeight: 700,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        marginBottom: `calc(${theme.spacing.xs} / 2)`,
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },

    afterFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing.xl,
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,
        borderTop: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }`,

        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
        },
    },

    social: {
        [theme.fn.smallerThan('sm')]: {
            marginTop: theme.spacing.xs,
        },
    },
}));

interface FooterLinksProps {
    data: {
        title: string;
        links: { label: string; link: string }[];
    }[];
}

export function FooterLinks({ data }: FooterLinksProps) {
    const { classes } = useStyles();

    const groups = data.map((group) => {
        const links = group.links.map((link, index) => (
            <Text
        key={index}
        className={classes.link}
        component="a"
        href={link.link}
        onClick={(event) => event.preventDefault()}
    >
        {link.label}
    </Text>
    ));

        return (
            <div className={classes.wrapper} key={group.title}>
                <Text className={classes.title}>{group.title}</Text>
                {links}
            </div>
        );
    });

    return (
        <footer className={classes.footer}>
            {/*<Container className={classes.inner}>*/}
            {/*    <div className={classes.logo}>*/}
            {/*        <MantineLogo size={30} />*/}
            {/*        <Text size="xs" color="dimmed" className={classes.description}>*/}
            {/*            Build fully functional accessible web applications faster than ever*/}
            {/*        </Text>*/}
            {/*    </div>*/}
            {/*    <div className={classes.groups}>{groups}</div>*/}
            {/*</Container>*/}
            <Container className={classes.afterFooter}>
                <Text color="dimmed" size="sm">
                    © 2024 newstats.eu. All rights reserved.
                </Text>

                <Group spacing={0} className={classes.social} position="right" noWrap>
                    <a href="https://twitter.com/newstatseu" target="_blank" rel="noopener noreferrer">
                        <ActionIcon size="lg">
                            <IconBrandTwitter size="1.05rem" stroke={1.5}/>
                        </ActionIcon>
                    </a>
                    <a href="https://www.facebook.com/NewStatsOfficial" target="_blank" rel="noopener noreferrer">
                        <ActionIcon size="lg">
                            <IconBrandFacebook size="1.05rem" stroke={1.5}/>
                        </ActionIcon>
                    </a>
                        {/*<ActionIcon size="lg">*/}
                        {/*    <IconBrandInstagram size="1.05rem" stroke={1.5} />*/}
                        {/*</ActionIcon>*/}
                </Group>
            </Container>
        </footer>
);
}
