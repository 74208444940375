import { ThemeIcon, Text, Title, Container, SimpleGrid, createStyles, rem } from '@mantine/core';
import {
    IconGauge,
    IconCookie,
    IconUser,
    IconMessage2,
    IconLock,
    IconBallBasketball,
    IconBrain, IconHistory
} from '@tabler/icons-react';

export const MOCKDATA = [
    {
        icon: IconBallBasketball,
        title: 'Global League Coverage',
        description:
            'Dive into comprehensive predictions for basketball leagues across the globe. Whether it\'s the NBA, EuroLeague, or any major league worldwide, our advanced algorithms ensure you\'re covered with the most accurate insights'
    },
    {
        icon: IconBrain,
        title: 'Smart Machine Learning',
        description:
            'At the heart of our predictions lies a sophisticated Machine Learning model, fine-tuned to analyze and learn from patterns in historical data. Experience the power of technology that adapts and improves with every game.',
    },
    {
        icon: IconHistory,
        title: 'Decade of Data',
        description:
            ' Our models are built on a solid foundation of over a decade\'s worth of comprehensive historical data. This extensive dataset ensures that our predictions are not just guesses but are backed by thorough analysis and trends observed over many years.',
    }
];

interface FeatureProps {
    icon: React.FC<any>;
    title: React.ReactNode;
    description: React.ReactNode;
}

export function Feature({ icon: Icon, title, description }: FeatureProps) {
    return (
        <div>
            <ThemeIcon variant="light" size={40} radius={40}>
                <Icon size="1.1rem" stroke={1.5} />
            </ThemeIcon>
            <Text mt="sm" mb={7}>
                {title}
            </Text>
            <Text size="sm" color="dimmed" sx={{ lineHeight: 1.6 }}>
                {description}
            </Text>
        </div>
    );
}

const useStyles = createStyles((theme) => ({
    wrapper: {
        paddingTop: `calc(${theme.spacing.xl} * 4)`,
        paddingBottom: `calc(${theme.spacing.xl} * 4)`,
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 900,
        marginBottom: theme.spacing.md,
        textAlign: 'center',

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(28),
            textAlign: 'left',
        },
    },

    description: {
        textAlign: 'center',

        [theme.fn.smallerThan('sm')]: {
            textAlign: 'left',
        },
    },
}));

interface FeaturesGridProps {
    title: React.ReactNode;
    description: React.ReactNode;
    data?: FeatureProps[];
}

export function FeaturesSection({ title, description, data = MOCKDATA }: FeaturesGridProps) {
    const { classes } = useStyles();
    const features = data.map((feature, index) => <Feature {...feature} key={index} />);

    return (
        <Container className={classes.wrapper}>
            {/*<Title className={classes.title}>{title}</Title>*/}

            {/*<Container size={560} p={0}>*/}
            {/*    <Text size="sm" className={classes.description}>*/}
            {/*        {description}*/}
            {/*    </Text>*/}
            {/*</Container>*/}

            <SimpleGrid
                mt={60}
                cols={3}
                spacing={50}
                breakpoints={[
                    { maxWidth: 980, cols: 2, spacing: 'xl' },
                    { maxWidth: 755, cols: 1, spacing: 'xl' },
                ]}
            >
                {features}
            </SimpleGrid>
        </Container>
    );
}
