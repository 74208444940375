import {Badge, Button, Center, createStyles, Group, rem, Space, Stack, Text} from "@mantine/core";
import {useContext} from "react";
import {PredictionsCartContext} from "../PredictionsCartContext";
import {map} from "lodash";

function getBadge(tags) {
    if(tags.includes("SUPER_RECOMMENDED")){
        return <Badge size="md" color="orange">High Confidence</Badge>
    } else if(tags.includes("RECOMMENDED")){
        return <Badge size="md" color="green">Recommended</Badge>
    }
}

function PredictionUnit(props){
    const type = props.type;
    const data = props.data;

    const predictionsCart = useContext(PredictionsCartContext);
    const addedToCart = predictionsCart.isPredictionInCart(data);
    const predictionUnitBought = (data.desc!=null);
    return (
        <Stack spacing={0}>
            <Text ta="center" fz="lg" fw={700}>{type}</Text>
                {predictionUnitBought ?
                    <Text ta="center">{data.desc}</Text> :
                    addedToCart ?
                    <Button size="md" variant="subtle" color="red" onClick={() => predictionsCart.removePrediction(data)}>Remove</Button> :
                    <Button size="md" variant="subtle" onClick={() => predictionsCart.addPrediction(data)}>Add To Cart</Button>
                }
                <Group position="center" spacing={0}>
                    {getBadge(data.tags)}
                </Group>
        </Stack>
    );
}

export default PredictionUnit;
