import {createContext, useState} from "react";
import axios from "axios";
import {useMutation} from "@tanstack/react-query";

export const PredictionsCartContext = createContext({
    predictionsInCart:[],
    addPrediction: () => {},
    removePrediction: () => {},
    isPredictionInCart: () => {},
    getTotalCost: () => {},
    checkout:() => {},
});

export function PurchasedPredictionsProvider({children}) {

    const [predictionsInCart,setPredictionsInCart] = useState([]);

    async function createTodo(data) {
        try {
            return await axios.post(process.env.REACT_APP_BACKEND_URL+'/create-checkout-session', data);
        } catch (e) {
            throw e;
        }
    }

    const mutation = useMutation(createTodo,{
        onSuccess: (response, variables, context) => {
            window.location.assign(response.data.url);
        }
    })

    function isPredictionInCart(predictionItem) {
        const predictionItemFound = predictionsInCart.find(one => (
            one.origin_code === predictionItem.origin_code &&
            one.origin_lid === predictionItem.origin_lid &&
            one.metric === predictionItem.metric &&
            one.game.year === predictionItem.game.year
        ));
        return predictionItemFound !== undefined
    }

    function getTotalCost() {
        return predictionsInCart.length * 0.5;
    }

    function removePrediction(predictionItem) {
        const inCart = isPredictionInCart(predictionItem);

        if(inCart) {
            setPredictionsInCart(
                predictionsInCart =>
                    predictionsInCart.filter(current => {
                      return (
                          current.origin_code !== predictionItem.origin_code ||
                          current.origin_lid !== predictionItem.origin_lid ||
                          current.metric !== predictionItem.metric ||
                          current.game.year !== predictionItem.game.year
                      )
                    })
            )
        }

    }

    function addPrediction(predictionItem) {
        const existsAlready = isPredictionInCart(predictionItem)

        if(!existsAlready) {
         setPredictionsInCart([
             ...predictionsInCart,
             predictionItem
         ])
        }
    }

    function checkout(purchasedItems) {
        mutation.mutate(purchasedItems);
    }

    const contextValue = {
        predictionsInCart,
        addPrediction,
        isPredictionInCart,
        removePrediction,
        getTotalCost,
        checkout,
    }

    return (
        <PredictionsCartContext.Provider value={contextValue}>
            {children}
        </PredictionsCartContext.Provider>
    )
}

export default PurchasedPredictionsProvider;
