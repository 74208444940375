import { useState } from 'react';
import {
    createStyles,
    Header,
    Container,
    Group,
    Burger,
    rem,
    Drawer,
    ScrollArea,
    Divider,
    UnstyledButton, Center, Box, Collapse, Button, Avatar, Menu,Text
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { MantineLogo } from '@mantine/ds';
import {
    IconChevronDown,
    IconHeart, IconLogout,
    IconMessage, IconPlayerPause,
    IconSettings,
    IconStar,
    IconSwitchHorizontal, IconTrash
} from "@tabler/icons-react";
import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import {useQuery} from "@tanstack/react-query";
import {NavLink} from "react-router-dom";

const useStyles = createStyles((theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
    },

    links: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    burger: {
        [theme.fn.largerThan('xs')]: {
            display: 'none',
        },
    },

    link: {
        display: 'block',
        lineHeight: 1,
        padding: `${rem(8)} ${rem(12)}`,
        borderRadius: theme.radius.sm,
        textDecoration: 'none',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        fontSize: theme.fontSizes.sm,
        fontWeight: 500,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
    },

    linkActive: {
        '&, &:hover': {
            backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
            color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
        },
    },
    hiddenMobile: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    hiddenDesktop: {
        [theme.fn.largerThan('sm')]: {
            display: 'none',
        },
    },
}));

interface HeaderSimpleProps {
    links: { link: string; label: string }[];
}

async function onLogout() {
    await signOut();
    window.location.href = "/";
}

export function HeaderSimple({ links }: HeaderSimpleProps) {
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
    const [userMenuOpened, setUserMenuOpened] = useState(false);
    const { classes, cx } = useStyles();
    const { theme } = useStyles();
    const whoamiQuery = useQuery(["whoami"],() =>
        fetch(process.env.REACT_APP_BACKEND_URL+"/whoami").then((res) => res.json()),{
        staleTime:'infinity',refetchOnWindowFocus:false
    }
    );
    let email = "";
    if(whoamiQuery.data){
        email = whoamiQuery.data.email;
    }

    const items = links.map((link, index) => (
        <NavLink
            key={index}
            to={link.link}
            className={({ isActive, isPending }) =>
                isPending ? classes.link : isActive ? cx(classes.link,classes.linkActive) : classes.link
            }
        >
            {link.label}
        </NavLink>
    ));

    return (
        <>
        <Header height={60}>
            <Container className={classes.header}>
                <Text
                    variant="gradient"
                    gradient={{ from: '#001D3D', to: '#4F79AB', deg: 45 }}
                    ta="center"
                    fz="xl"
                    fw={700}
                >
                    BBall Predictions
                </Text>
                <Group spacing={5} className={classes.links}>
                    {items}
                </Group>

                <Burger opened={drawerOpened} onClick={toggleDrawer} className={classes.hiddenDesktop} />
                <Menu
                    width={260}
                    className={classes.hiddenMobile}
                    position="bottom-end"
                    transitionProps={{ transition: 'pop-top-right' }}
                    onClose={() => setUserMenuOpened(false)}
                    onOpen={() => setUserMenuOpened(true)}
                    withinPortal
                >
                    <Menu.Target>
                        <UnstyledButton
                            className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                        >
                            <Group spacing={7}>
                                <Text weight={500} size="sm" sx={{ lineHeight: 1 }} mr={3}>
                                    {email}
                                </Text>
                                <IconChevronDown size={rem(12)} stroke={1.5} />
                            </Group>
                        </UnstyledButton>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item onClick={onLogout}  icon={<IconLogout size="0.9rem" stroke={1.5} />}>Logout</Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </Container>
        </Header>
            <Drawer
                opened={drawerOpened}
                onClose={closeDrawer}
                size="100%"
                padding="md"
                className={classes.hiddenDesktop}
                zIndex={1000000}
            >
                <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
                    <a href="/" className={classes.link}>
                        Home
                    </a>
                    {/*<UnstyledButton className={classes.link} onClick={toggleLinks}>*/}
                    {/*    <Center inline>*/}
                    {/*        <Box component="span" mr={5}>*/}
                    {/*            Features*/}
                    {/*        </Box>*/}
                    {/*        <IconChevronDown size={16} color={theme.primaryColor} />*/}
                    {/*    </Center>*/}
                    {/*</UnstyledButton>*/}
                    <a href="/subscriptions" className={classes.link}>
                        Subscriptions
                    </a>
                    <a className={classes.link} href="/" onClick={(e) => {
                        e.preventDefault(); // Prevents the default link action
                        onLogout();
                    }}>Log out</a>


                    {/*<Divider my="sm" color={'gray.1'} />*/}

                    {/*<Group position="center" grow pb="xl" px="md">*/}
                    {/*    <Button variant="default">Log in</Button>*/}
                    {/*    <Button>Sign up</Button>*/}
                    {/*</Group>*/}
                </ScrollArea>
            </Drawer>
        </>
    );
}
