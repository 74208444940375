import { useForm } from '@mantine/form';
import { Button, Container, Group, SimpleGrid, Textarea, TextInput, Title } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

export function GetInTouchSimple() {
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const fetchEmail = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/whoami`);
                setEmail(response.data.email);
            } catch (error) {
                console.error("Failed to fetch email:", error);
            }
        };

        fetchEmail();
    }, []);

    const form = useForm({
        initialValues: {
            name: '',
            email: '',
            subject: '',
            message: '',
        },
        validate: {
            name: (value) => value.trim().length < 2 ? 'Name must be at least 2 characters' : null,
            email: (value) => !/^\S+@\S+$/.test(value) ? 'Invalid email' : null,
            subject: (value) => value.trim().length === 0 ? 'Subject is required' : null,
            message: (value) => value.trim().length === 0 ? 'Message cannot be empty' : null, // Validation rule for message
        },
    });

    useEffect(() => {
        if (email) {
            form.setFieldValue('email', email);
        }
    }, [email]);

    const handleSubmit = async (values) => {
        setIsSubmitting(true);
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/submit-form`, values);
            form.reset(); // Clear the form
            showNotification({
                title: 'Success',
                message: 'Message sent successfully',
                icon: <IconCheck />,
            });
        } catch (error) {
            console.error("Failed to submit form:", error);
            // Handle submission error (show notification, log, etc.)
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Container>
            <form onSubmit={form.onSubmit(handleSubmit)}>
                <Title order={2} size="h1" style={{ fontFamily: 'Greycliff CF, var(--mantine-font-family)' }} fw={900} ta="center">
                    Get in touch
                </Title>
                <SimpleGrid cols={{ base: 1, sm: 2 }} mt="xl">
                    <TextInput label="Name" placeholder="Your name" {...form.getInputProps('name')} />
                    <TextInput label="Email" placeholder="Your email" readOnly {...form.getInputProps('email')} />
                </SimpleGrid>
                <TextInput label="Subject" placeholder="Subject" mt="md" {...form.getInputProps('subject')} />
                <Textarea label="Message" placeholder="Your message" mt="md" autosize minRows={4} {...form.getInputProps('message')} />
                <Group position="right" mt="md">
                    <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
                        Send message
                    </Button>
                </Group>
            </form>
        </Container>
    );
}
