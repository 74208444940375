import {PredictionsCartContext} from "../PredictionsCartContext";
import {useContext} from "react";
import {
    Button,
    Card,
    Center,
    Container,
    createStyles,
    Divider,
    Grid,
    rem,
    SimpleGrid,
    Stack,
    Text
} from "@mantine/core";
import {format} from "date-fns-tz";
import {parseISO} from "date-fns";
import PredictionUnit from "./PredictionUnit";

const useStyles = createStyles((theme) => ({
    card: {
        borderRadius: theme.radius.md,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        transition: 'box-shadow 150ms ease, transform 100ms ease',

        '&:hover': {
            boxShadow: theme.shadows.md,
            transform: 'scale(1.05)',
        },
    },
}));

function PredictionGame(props) {

    const predictionGame = props.data;
    const game_date = format(parseISO(predictionGame["game"]["game_date"]+"Z"),'MMM dd yyyy, p')
    const { classes, theme } = useStyles();

    return (
        <Card withBorder radius="md" className={classes.card}>
        <Card.Section>
            <Center>
                <Stack spacing={0}>
                    <Text ta="center" fz="lg" fw={700}>
                        {predictionGame.game.home_name} - {predictionGame.game.away_name}
                    </Text>
                    <Text ta="center">{game_date}</Text>
                </Stack>
            </Center>
        </Card.Section>
        <Divider my="sm" />
        <Card.Section>
            <SimpleGrid cols={2}>
                    <PredictionUnit type={"Overunder"} data={predictionGame.overunder}></PredictionUnit>
                    <PredictionUnit type={"Handicap"} data={predictionGame.handicap}></PredictionUnit>
            </SimpleGrid>
        </Card.Section>
        </Card>
    )
}
export default PredictionGame;
