import {HeroSection} from "./HeroSection";
import {FeaturesSection} from "./FeaturesSection";
import {FooterLinks} from "./FooterLinks";
import {Helmet} from "react-helmet-async";

const footerLinks = [
    {
        "title": "About",
        "links": [
            {
                "label": "Features",
                "link": "#"
            },
            {
                "label": "Pricing",
                "link": "#"
            },
            {
                "label": "Support",
                "link": "#"
            },
            {
                "label": "Forums",
                "link": "#"
            }
        ]
    },
    {
        "title": "Project",
        "links": [
            {
                "label": "Contribute",
                "link": "#"
            },
            {
                "label": "Media assets",
                "link": "#"
            },
            {
                "label": "Changelog",
                "link": "#"
            },
            {
                "label": "Releases",
                "link": "#"
            }
        ]
    },
    {
        "title": "Community",
        "links": [
            {
                "label": "Join Discord",
                "link": "#"
            },
            {
                "label": "Follow on Twitter",
                "link": "#"
            },
            {
                "label": "Email newsletter",
                "link": "#"
            },
            {
                "label": "GitHub discussions",
                "link": "#"
            }
        ]
    }
];

export function LandingPage(){
    return <>
        <Helmet>
            <meta name="description" content="Betting predictions generated from machine learning techniques on all major basketball leagues. Euroleague, Israeli Basketball Premier League, NBA, Eurocup, German BBL, Spanish ACB, French LNB Pro A, Turkish Basketball Super League,Adriatic League,Lega Basket Serie A, Greek Basket League"/>
        </Helmet>
        <HeroSection/>
        <FeaturesSection title={"Title"} description={"Description"}/>
        <FooterLinks data={footerLinks}/>
          </>
}
