import {Outlet} from "react-router-dom";
import {HeaderSimple} from "./components/HeaderSimple";
import {AppShell, Header, LoadingOverlay, Navbar} from "@mantine/core";
import {FooterLinks} from "./components/FooterLinks";
import {useSessionContext} from "supertokens-auth-react/recipe/session";
import {LandingPage} from "./components/LandingPage";
import React from "react";
import {Notifications} from "@mantine/notifications";

const links = [
        {
            "link": "/",
            "label": "Home"
        },
        {
            "link": "/subscriptions",
            "label": "Subscriptions"
        },
        {
            "link": "/contact",
            "label": "Contact"
        },
    ]

const footerLinks = [
        {
            "title": "About",
            "links": [
                {
                    "label": "Features",
                    "link": "#"
                },
                {
                    "label": "Pricing",
                    "link": "#"
                },
                {
                    "label": "Support",
                    "link": "#"
                },
                {
                    "label": "Forums",
                    "link": "#"
                }
            ]
        },
        {
            "title": "Project",
            "links": [
                {
                    "label": "Contribute",
                    "link": "#"
                },
                {
                    "label": "Media assets",
                    "link": "#"
                },
                {
                    "label": "Changelog",
                    "link": "#"
                },
                {
                    "label": "Releases",
                    "link": "#"
                }
            ]
        },
        {
            "title": "Community",
            "links": [
                {
                    "label": "Join Discord",
                    "link": "#"
                },
                {
                    "label": "Follow on Twitter",
                    "link": "#"
                },
                {
                    "label": "Email newsletter",
                    "link": "#"
                },
                {
                    "label": "GitHub discussions",
                    "link": "#"
                }
            ]
        }
    ]

function Root() {
    let sessionContext = useSessionContext();
    if (sessionContext.loading) {
        return <LoadingOverlay />;
    }
    if (sessionContext.doesSessionExist) {
        return (
            <>
                <AppShell
                    layout="alt"
                    padding="md"
                    footer={<FooterLinks data={footerLinks}/>}
                    header={<HeaderSimple links={links}/>}
                    styles={(theme) => ({
                        main: {backgroundColor: theme.colors.gray[0]},
                    })}
                >
                    <Outlet/>
                </AppShell>
            </>
        );
    } else {
        return <LandingPage/>
    }
}

export default Root;
