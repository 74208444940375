import {
    Box,
    Button,
    Container,
    Divider,
    Flex,
    Group,
    Stack,
    Title,
    Text,
    useMantineTheme,
    Switch,
    Indicator
} from "@mantine/core";
import {useContext} from "react";
import {PredictionsCartContext} from "../PredictionsCartContext";

function SubscriptionsOptions(props) {
    const theme = useMantineTheme();
    const predictionsCart = useContext(PredictionsCartContext);
    return <>
        <Box
            sx={{
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[10] : 'hsl(240, 78%, 98%)',
                height: '100vh',
                position: 'relative',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                '@media (max-width:755px)': {
                    paddingBlock: '5rem',
                    height: '100%',
                }
            }}
        >
        <Group
            sx={{ zIndex: 50 }}
        >
            <Stack spacing={40} >
                {/** header section */}
                <Flex
                    direction='column'
                    gap={10}
                    align='center'
                    justify='start'
                >
                    <Title
                        order={2}
                        color= {theme.colorScheme === 'dark' ? 'white' : 'hsl(233, 13%, 49%)'}
                    >Subscription Plans</Title>
                </Flex>
                {/** cards section */}
                <Group>
                    <Flex
                        align={'center'}
                        direction={{ base: 'column', sm: 'row' }}
                        color={'hsl(232, 13%, 33%)'}
                        gap={{base: '1.5rem', sm: 0}}
                    >
                        <Box
                            sx={{
                                boxShadow: '0px 30px 50px -7px rgba(0,0,0,0.1)',
                                height: '24rem',
                                width: '17rem',
                                paddingInline: '1.5rem',
                                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : 'white',
                                borderRadius: '0.7rem 0 0 0.7rem',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',

                                '@media (max-width: 755px)': {
                                    width: '19rem',
                                    borderRadius: '0.7rem',
                                },
                                '@media (min-width: 756px) and (max-width: 820px)': {
                                    width: '15rem',
                                    borderRadius: '0.7rem 0 0 0.7rem',
                                },
                            }}
                        >
                            <Stack w={'100%'} align={'center'} spacing={20}>
                                <Text sx={{
                                    fontWeight: 700,
                                    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : 'hsl(233, 13%, 49%)',
                                }} fz={'md'}>1 Month</Text>
                                <Indicator inline position="bottom-center" label="Regular price 20€" size={16}>
                                <Title
                                    order={2}
                                    sx={{
                                        color: theme.colorScheme === 'dark' ? 'white' : 'hsl(232, 13%, 33%)',
                                        fontSize: 50,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2
                                    }}
                                >
                                    <Text fz={'2rem'}></Text>
                                    {'5€'}
                                </Title>
                                </Indicator>

                                <Stack w={'100%'} align='center' spacing={10} sx={{color: theme.colorScheme === 'light' && 'hsl(233, 13%, 49%)'}}>
                                    <Divider sx={{ width: '100%', borderColor: theme.colorScheme === 'dark' &&'gray', opacity: theme.colorScheme === 'dark' && 0.7 }} />
                                    <Text fz={'lg'} fw={600} c="red">Limited time offer</Text>
                                    <Divider sx={{ width: '100%', borderColor: theme.colorScheme === 'dark' &&'gray', opacity: theme.colorScheme === 'dark' && 0.7 }} />
                                    <Text fz={'sm'} fw={600} >Access to all predictions</Text>
                                    <Divider sx={{ width: '100%', borderColor: theme.colorScheme === 'dark' &&'gray', opacity: theme.colorScheme === 'dark' && 0.7 }} />
                                    <Text fz={'sm'} fw={600} >Access to all leagues</Text>
                                    <Divider sx={{ width: '100%', borderColor: theme.colorScheme === 'dark' &&'gray', opacity: theme.colorScheme === 'dark' && 0.7 }} />
                                </Stack>
                                <Button
                                    variant='gradient'
                                    gradient={{ from: 'hsl(236, 72%, 79%)', to: 'hsl(237, 63%, 64%)' }}
                                    w='100%'
                                    onClick={() => {
                                        predictionsCart.checkout({"type":"month"});
                                    }}
                                >
                                    Buy now
                                </Button>
                            </Stack>
                        </Box>
                        <Box
                        sx={{
                            boxShadow: '0px 30px 50px -7px rgba(0,0,0,0.1)',
                            height: '24rem',
                            width: '18rem',
                            paddingInline: '1.5rem',
                            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : 'white',
                            borderRadius: '0 0.7rem 0.7rem 0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                            '@media (max-width: 755px)': {
                                width: '19rem',
                                borderRadius: '0.7rem',
                            },
                            '@media (min-width: 756px) and (max-width: 820px)': {
                                width: '15rem',
                                borderRadius: '0 0.7rem 0.7rem 0',
                            },
                        }}
                    >
                        <Stack w={'100%'} align={'center'} spacing={20}>
                            <Text sx={{
                                fontWeight: 700,
                                color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : 'hsl(233, 13%, 49%)',
                            }} fz={'md'}>1 Year</Text>
                            <Title
                                order={2}
                                sx={{
                                    color: theme.colorScheme === 'dark' ? 'white' : 'hsl(232, 13%, 33%)',
                                    fontSize: 50,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 5
                                }}
                            >
                                <Text fz={'2rem'}></Text>
                                {'180€'}
                            </Title>
                            <Stack w={'100%'} align='center' spacing={10} sx={{color: theme.colorScheme === 'light' && 'hsl(233, 13%, 49%)'}}>
                                <Divider sx={{ width: '100%', borderColor: theme.colorScheme === 'dark' &&'gray', opacity: theme.colorScheme === 'dark' && 0.7 }} />
                                <Text fz={'md'} fw={600} c="red">Save 60€ compared to monthly</Text>
                                <Divider sx={{ width: '100%', borderColor: theme.colorScheme === 'dark' &&'gray', opacity: theme.colorScheme === 'dark' && 0.7 }} />
                                <Text fz={'sm'} fw={600} >Access to all predictions</Text>
                                <Divider sx={{ width: '100%', borderColor: theme.colorScheme === 'dark' &&'gray', opacity: theme.colorScheme === 'dark' && 0.7 }} />
                                <Text fz={'sm'} fw={600} >Access to all leagues</Text>
                                <Divider sx={{ width: '100%', borderColor: theme.colorScheme === 'dark' &&'gray', opacity: theme.colorScheme === 'dark' && 0.7 }} />
                            </Stack>
                            <Button
                                disabled
                                variant='gradient'
                                gradient={{ from: 'hsl(236, 72%, 79%)', to: 'hsl(237, 63%, 64%)' }}
                                w='100%'
                            >
                                Not available in demo mode
                            </Button>
                        </Stack>

                    </Box>
                    </Flex>
                </Group>
            </Stack>
        </Group>
        </Box>
    </>
}

export default SubscriptionsOptions;
