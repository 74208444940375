import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {MantineProvider} from "@mantine/core";
import initializeSuperTokens from "./SuperTokensInitializer";
import {SuperTokensWrapper} from "supertokens-auth-react";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import PurchasedPredictionsProvider from "./PredictionsCartContext";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {getSuperTokensRoutesForReactRouterDom} from "supertokens-auth-react/ui";
import * as reactRouterDom from "react-router-dom";
import {ThirdPartyEmailPasswordPreBuiltUI} from "supertokens-auth-react/recipe/thirdpartyemailpassword/prebuiltui";
import SubscriptionsOptions from "./components/SubscriptionsOptions";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import Root from "./Root";
import theme from "./theme.js";
import {Notifications} from "@mantine/notifications";
import {GetInTouchSimple} from "./components/GetInTouchSimple";
import {HelmetProvider} from "react-helmet-async";

initializeSuperTokens();

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <HelmetProvider>
      <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
          <Notifications />
          <SuperTokensWrapper>
              <BrowserRouter>
              <QueryClientProvider client={queryClient}>
                  <PurchasedPredictionsProvider>
                      <Routes>
                          {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [ThirdPartyEmailPasswordPreBuiltUI])}
                          <Route path="/" element={<Root/>}>
                              <Route index element={<App/>}/>
                              <Route path="subscriptions" element={<SubscriptionsOptions/>}/>
                              <Route path="contact" element={<GetInTouchSimple/>}/>
                          </Route>
                      </Routes>
                  </PurchasedPredictionsProvider>
              </QueryClientProvider>
              </BrowserRouter>
          </SuperTokensWrapper>
      </MantineProvider>
      </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
