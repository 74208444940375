// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatsGrid_root__vUxdO {
    padding: calc(var(--mantine-spacing-xl) * 1.5);
}

.StatsGrid_value__VtZQ- {
    font-size: rem(24px);
    font-weight: 700;
    line-height: 1;
}

.StatsGrid_diff__TTMEO {
    line-height: 1;
    display: flex;
    align-items: center;
}

.StatsGrid_icon__\\+2tXG {
    color: light-dark(var(--mantine-color-gray-4), var(--mantine-color-dark-3));
}

.StatsGrid_title__yHvlW {
    font-weight: 700;
    text-transform: uppercase;
}
`, "",{"version":3,"sources":["webpack://./src/StatsGrid.module.css"],"names":[],"mappings":"AAAA;IACI,8CAA8C;AAClD;;AAEA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,2EAA2E;AAC/E;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;AAC7B","sourcesContent":[".root {\n    padding: calc(var(--mantine-spacing-xl) * 1.5);\n}\n\n.value {\n    font-size: rem(24px);\n    font-weight: 700;\n    line-height: 1;\n}\n\n.diff {\n    line-height: 1;\n    display: flex;\n    align-items: center;\n}\n\n.icon {\n    color: light-dark(var(--mantine-color-gray-4), var(--mantine-color-dark-3));\n}\n\n.title {\n    font-weight: 700;\n    text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `StatsGrid_root__vUxdO`,
	"value": `StatsGrid_value__VtZQ-`,
	"diff": `StatsGrid_diff__TTMEO`,
	"icon": `StatsGrid_icon__+2tXG`,
	"title": `StatsGrid_title__yHvlW`
};
export default ___CSS_LOADER_EXPORT___;
